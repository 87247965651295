import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

let kejianrouter = new Router({
    routes: [{
        path: '/',
        name: 'homePage',
        component: () => import('./views/HomePage.vue')
    },
    {
        path: '/clientCase',
        name: 'clientCase',
        component: () => import('./views/ClientCase.vue')
    },
    {
        path: '/serviceItem',
        name: 'serviceItem',
        component: () => import('./views/ServiceItem.vue')
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('./views/AboutUs.vue')
    },
    {
        path: '/contactItem',
        name: 'contactItem',
        component: () => import('./views/ContactItem.vue')
    },
    {
        path: '/partCase',
        name: 'partCase',
        component: () => import('./views/PartCase.vue')
    },
]
});

export default kejianrouter;