<template>
  <div id="app">
    <div class="headerBoxBox">
      <div class="headerBox">
        <div class="logoPhone">哆啦哆科技</div>
        <div class="logoPhoneMew">深圳市哆啦哆科技有限公司</div>
        <div class="m-tigger" @click="tiggerEvt()">
        </div>
        <div class="headerBoxPath">
          <div v-for="(item, index) in menuItems" :key="index" @click="setActive(index)">
            <div 
            :style="{ fontWeight: activeIndex === index ? 'bold' : 'normal' }"
              :class="{ active: activeIndex === index }"
              >
              {{ item.name }}
            </div>
          </div>

          <div class="phoneNumber">
            <a :href="'tel:' + phoneNumber">罗先生{{ phoneNumber }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="side">
      <ul>
        <li>
          <a href="http://wpa.qq.com/msgrd?v=3&uin=774232644&site=qq&menu=yes" target="_Blank">
            <div class="sidebox">
              <img src="./assets/skin/side_icon01.png">
              在线咨询
            </div>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);">
            <div class="sidebox">
              <img src="./assets/skin/side_icon03.png">
              <a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="side2">
      <ul>
        <li @mouseover="showWeixin = true" @mouseleave="showWeixin = false">
          <a href="index.html">
            <img src="./assets/skin/r_icon1.png" alt="Icon 1" />
          </a>
          <!--  -->
          <div class="weixin" v-if="showWeixin">
            <div></div>
            <img src="./assets/cd.png" alt="QR Code" />
          </div>
        </li>
        <li>
          <a @click="goTop" class="sidetop">
            <img src="./assets/skin/r_icon2.png" alt="Go Top" />
          </a>
        </li>
      </ul>
    </div>
    <!-- </el-header> -->
    <div class="ulMenuDemo" v-if="tiggerEvtActive">
      <div @click="goToEvt('/')">首页</div>
      <div @click="goToEvt('/clientCase')">客户案例</div>
      <div @click="goToEvt('/serviceItem')">服务项目</div>
      <div @click="goToEvt('/aboutUs')">关于我们</div>
      <div @click="goToEvt('/contactItem')">联系我们</div>
      <div>罗先生 <a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a></div>
    </div>
    <el-main>
      <router-view />
    </el-main>
    <div id="fd" class="index-fd pr">
      <div class="map-bg3">
      </div>
      <div class="wp">
        <div class="fd-top">
          <dl class="fd-one">
            <dt>
              关于我们
            </dt>
            <dd>
              <ul class="ul-fd">
                <li>
                  <router-link to="/aboutUs#aboutBotto">我们是谁</router-link>
                </li>
                <li>
                  <router-link to="/aboutUs#aboutUs">我们服务的客户</router-link>
                </li>
                <li>
                  <router-link to="/aboutUs#aboutTeam">我们的团队</router-link>
                </li>
              </ul>
            </dd>
          </dl>
          <dl class="fd-two">
            <dt>
              我们的服务
            </dt>
            <dd>
              <ul class="ul-fd">
                <li>
                  <router-link to="/serviceItem">网站建设</router-link>

                </li>
                <li>
                  <router-link to="/serviceItem">H5响应式 交互网站</router-link>
                </li>
                <li>
                  <router-link to="/serviceItem"> 移动端 & 微网站定制</router-link>
                </li>
                <li>
                  <router-link to="/serviceItem"> 服务流程</router-link>
                </li>
                <li>
                  <router-link to="/serviceItem"> 行业解决方案</router-link>
                </li>
              </ul>
            </dd>
          </dl>
          <dl class="wo">
            <dt>
              我们的案例
            </dt>
            <dd class="ul-dd">
              <ul class="ul-fd">
                <li>
                  <a>
                    蓝牙设备对接
                  </a>
                </li>
                <li>
                  <a>
                    Wi-Fi设备对接
                  </a>
                </li>
                <li>
                  <a>
                    户外报警器定制
                  </a>
                </li>
                <li>
                  <a>
                    律所积分系统
                  </a>
                </li>


              </ul>
              <ul class="ul-fd">
                <li>
                  <a>
                    绘本点读系列
                  </a>
                </li>
                <li>
                  <a>
                    数字农业
                  </a>
                </li>
                <li>
                  <a>
                    分销商城
                  </a>
                </li>
                <li>
                  <a>
                    贷款金融
                  </a>
                </li>
                <li>
                  <a>
                    硬件对接
                  </a>
                </li>

              </ul>
              <ul class="ul-ff">
                <li>
                  <a>
                    生活服务
                  </a>
                </li>
                <li>
                  <a>
                    高级定制
                  </a>
                </li>
                <li>
                  <a>
                    政府机构
                  </a>
                </li>
                <li>
                  <a>
                    教育培训
                  </a>
                </li>
              </ul>
            </dd>
          </dl>

        </div>
        <div class="fdCopy">
          <div class="cp">深圳市哆啦哆科技有限公司</div>
          <div class="tel">技术负责人：<span> <a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a></span></div>
          <div class="er">
            <img src="./assets/cd.png" alt style="width: 140px; height: 140px;" />
            <div>扫一扫了解更多</div>
          </div>
        </div>
      </div>
      <div class="FdBottom"> Copyright © 2013-2024 深圳市哆啦哆科技有限公司 版权所有 粤ICP备2024261444号</div>
      <div class="FdBottomMew">Copyright©2013-2024<br />深圳市哆啦哆科技有限公司版权所有<br />粤ICP备2024261444号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      phoneNumber: '13828755858',
      showWeixin: false,
      router: true,
      defaultActive: "/",
      tiggerEvtActive: false,
      activeIndex: 0,
      menuItems: [
        { name: '首页', path: '/' },
        { name: '客户案例', path: '/clientCase' },
        { name: '服务项目', path: '/serviceItem' },
        { name: '关于我们', path: '/aboutUs' },
        { name: '联系我们', path: '/contactItem' }
      ]
    };
  },
  methods: {
    tiggerEvt() {
      this.tiggerEvtActive = !this.tiggerEvtActive
    },
    setActive(index) {
      const selectedItem = this.menuItems[index];
      if (this.isCurrentPage(selectedItem.path)) {
        alert(`您已经在当前页面: ${selectedItem.name}`);
      } else {
        this.activeIndex = index;
        this.goToEvt(selectedItem.path);
      }
    },
    isCurrentPage(item) {
      // 实现当前页面判断逻辑
      return this.activeIndex !== null && this.menuItems[this.activeIndex] === item;
    },
    goToEvt(path) {
      const currentPath = this.$route.path;
      if (path !== currentPath) {
        this.$router.push(path).then(() => {
          this.updateActiveState(path);
          this.tiggerEvtActive = false;
        });
      }
    },
    goTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    updateActiveIndex(path) {
      this.activeIndex = this.menuItems.findIndex(item => item.path === path);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    updateActiveState(path) {
      // 根据新的路径更新活动状态
      this.activeIndex = this.menuItems.findIndex(item => item.path === path);
    }
  },
  computed: {
    activeMenu() {
      return this.$route.path; // 绑定当前路由路径
    },
    currentPath() {
      return this.$route.path;
    }
  },
  watch: {
    currentPath(newPath) {
      this.updateActiveIndex(newPath);
    },
    $route(to) {
      // 当路由更改时更新活动状态并滚动到顶部
      this.updateActiveState(to.path);
      this.scrollToTop();
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

/* 手机 */
@media (max-width: 767px) {

  .side,
  .side2 {
    display: none;
  }

  .headerBoxPath {
    display: none;
  }

  .headerBoxBox {
    height: 50px
  }

  .headerBox {
    position: fixed;
    /* margin-top: 50px; */
    top: 0;
    width: 100vw;
    background: #fff;
    z-index: 999;
  }

  .headerBox .logoPhone {
    display: none;
  }

  .headerBox .logoPhoneMew {
    line-height: 54px;
    margin-left: 15px;
    font-size: 16px;
  }

  .ulMenuDemo {
    width: 100vw;
    height: 255px;
    position: fixed;
    margin-top: 50px;
    top: 0;
    color: #fff !important;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 999;
  }

  .ulMenuDemo div a {
    color: #fff !important;
    display: flex;
    padding-top: 5px;
    font-size: 16px;
    align-items: center;
  }

  .ulMenuDemo div {
    height: 40px;
    border: 0.5px solid #565656;
    display: flex;
    font-size: 16px;
    padding: 0 20px;
    align-items: center;
  }

  .ulMenuDemo div:last-child {
    border: none;
  }

  .el-menu--horizontal .el-menu-item {
    line-height: 80px !important;
    height: 80 !important;

  }

  .el-header {
    height: 50px !important;
    line-height: 50px;
    background-color: #fff;
    padding: 0;
    position: fixed;
    width: 100vw;
    z-index: 999999;
    border-bottom: 1px solid #ccc;
  }

  .m-tigger {
    position: absolute;
    top: 0;
    right: 10px;
    width: 50px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    cursor: pointer;
    background: url('./assets/menu_add.png') center center no-repeat;
    -webkit-background-size: 50px;
    background-size: 25px;
    height: 50px;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: transparent !important;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    color: #303030 !important;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 4px solid #B71F36 !important;
  }

  .el-menu--horizontal>.el-menu-item {
    color: #000 !important;
  }

  .el-header .logo {
    display: none;
  }

  .el-header .logoPhone {
    display: block;
  }


  .el-header .logo img {
    width: 58px;
    line-height: 56px;
    margin: 12px 0;
  }

  .el-main {
    padding: 0 !important;
  }

  .el-menu-demo {
    display: none;
  }

  .footer {
    width: 100%;
    height: 216px;
    overflow: hidden;
    background-color: #14679f;
  }

  .footer &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .footer &-content .content-nav {
    display: flex;
    justify-content: space-around;
  }


  .footer &-content .content-nav li {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: flex-start;
  }

  .footer &-content .content-nav li p {
    font-size: 20px;
    color: #d4edff;
    padding: 10px 0;
  }

  .footer &-content .content-nav li span {
    color: #f7f7f7;
    font-weight: 300;
    padding: 5px 0;
  }

  .footer &-content img {
    width: 170px;
    height: 170px;
    padding: 10px;
  }

  .footer .copyright {
    height: 30px;
    background: #125688;
  }

  .footer span {
    color: #fff;
    line-height: 30px;
  }

  /* 底部 */
  #fd {
    background: #E5E5E5;
    color: #707070;
    font-size: 18px;
    /* padding-top: 66px; */
  }


  #fd .wp .fd-top {
    display: flex;
    flex: 1;
  }

  #fd .wp .fd-top dl {
    margin-right: 20%;
  }

  #fd .wp .fd-top dl:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none !important;
    color: #707070;
    outline: none;
  }

  ul,
  li {
    list-style-type: none;
  }

  #fd .wp .fd-top dt {
    font-size: 20px;
    color: #595757;
    margin-bottom: 18px;
    font-weight: 500;
  }

  #fd .wp .fd-top .ul-fd li,
  .ul-ff li {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  #fd .wp .fd-top .wo .ul-dd {
    display: flex;
    margin-right: 25px;
  }

  #fd .wp .fd-top .wo .ul-dd .ul-fd {
    margin-right: 25px;
  }

  #fd .fdCopy {
    margin-top: 7px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 16px
  }

  #fd .fdCopy .tel span {
    font-size: 16px
  }

  #fd .wp .fd-top .fd-one,
  .fd-two {
    display: none;
  }

  #fd .wp .fd-top .wo {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 16px;
  }

  #fd .fdCopy .er {
    width: 140px;
    text-align: center;
    margin-top: 16px;
  }

  #fd .fdCopy .er div {
    margin-bottom: 6px;
    font-size: 13px;
  }

  #fd .FdBottom {
    display: none;
  }

  #fd .FdBottomMew {
    background: #333;
    color: #E5E5E5;
    font-size: 14px;
    height: 88px;
    max-height: 88px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    text-align: center
  }

}


/* 平板 */
@media (min-width: 768px) and (max-width: 1024px) {

  .side,
  .side2 {
    display: none;
  }

  .headerBoxPath {
    display: none;
  }

  .headerBoxBox {
    height: 50px
  }

  .headerBox {
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 999;
  }

  .headerBox .logoPhone {
    display: none;
  }

  .headerBox .logoPhoneMew {
    line-height: 54px;
    margin-left: 15px;
    font-size: 16px;
  }

  .ulMenuDemo {
    height: 255px;
    position: fixed;
    width: 100%;
    margin-top: 50px;
    top: 0;
    color: #fff !important;
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 999;
  }

  .ulMenuDemo div a {
    color: #fff !important;
    display: flex;
    padding-top: 5px;
    font-size: 16px;
    align-items: center;
  }

  .ulMenuDemo div {
    height: 40px;
    border: 0.5px solid #565656;
    display: flex;
    font-size: 16px;
    padding: 0 20px;
    align-items: center;
  }

  .ulMenuDemo div:last-child {
    border: none;
  }

  /* 平板样式 */
  .el-menu--horizontal .el-menu-item {
    line-height: 80px !important;
    height: 80 !important;

  }

  .el-header {
    height: 50px !important;
    line-height: 50px;
    background-color: #fff;
    padding: 0;
    position: fixed;
    z-index: 999999;
    border-bottom: 1px solid #ccc;
  }

  .m-tigger {
    position: absolute;
    top: 0;
    right: 10px;
    width: 50px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    line-height: 45px;
    cursor: pointer;
    background: url('./assets/menu_add.png') center center no-repeat;
    -webkit-background-size: 50px;
    background-size: 25px;
    height: 50px;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: transparent !important;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    color: #303030 !important;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 4px solid #B71F36 !important;
  }

  .el-menu--horizontal>.el-menu-item {
    color: #000 !important;
  }

  .el-header .logo {
    display: none;
  }

  .el-header .logoPhone {
    display: block;
  }


  .el-header .logo img {
    width: 58px;
    line-height: 56px;
    margin: 12px 0;
  }

  .el-main {
    padding: 0 !important;
  }

  .el-menu-demo {
    display: none;
  }

  .footer {
    /* width: 100%; */
    height: 216px;
    overflow: hidden;
    background-color: #14679f;
  }

  .footer &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .footer &-content .content-nav {
    display: flex;
    justify-content: space-around;
  }


  .footer &-content .content-nav li {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: flex-start;
  }

  .footer &-content .content-nav li p {
    font-size: 20px;
    color: #d4edff;
    padding: 10px 0;
  }

  .footer &-content .content-nav li span {
    color: #f7f7f7;
    font-weight: 300;
    padding: 5px 0;
  }

  .footer &-content img {
    width: 170px;
    height: 170px;
    padding: 10px;
  }

  .footer .copyright {
    height: 30px;
    background: #125688;
  }

  .footer span {
    color: #fff;
    line-height: 30px;
  }

  /* 底部 */
  #fd {
    background: #E5E5E5;
    color: #707070;
    font-size: 18px;
    /* padding-top: 66px; */
  }

  #fd .wp {
    width: 90%;
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
  }

  #fd .wp .fd-top {
    display: flex;
    flex: 1;
  }

  #fd .wp .fd-top dl {
    margin-right: 10%;
  }

  #fd .wp .fd-top dl:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: none !important;
    color: #707070;
    outline: none;
  }

  ul,
  li {
    list-style-type: none;
  }

  #fd .wp .fd-top dt {
    font-size: 16px;
    color: #595757;
    margin-bottom: 28px;
    font-weight: 500;
  }

  #fd .wp .fd-top .ul-fd li,
  .ul-ff li {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  #fd .wp .fd-top .wo .ul-dd {
    display: flex;
    margin-right: 25px;
  }

  #fd .wp .fd-top .wo .ul-dd .ul-fd {
    margin-right: 25px;
  }

  #fd .fdCopy {
    /* width: 40%; */
    margin-top: 10px;
    font-size: 14px
  }

  #fd .fdCopy .tel span {
    font-size: 16px
  }

  #fd .wp {
    padding-top: 66px;
  }

  #fd .fdCopy .er {
    width: 140px;
    text-align: center;
    margin-top: 16px;
  }

  #fd .fdCopy .er div {
    margin-bottom: 6px;
    font-size: 13px;
  }

  #fd .FdBottom {
    background: #333;
    color: #E5E5E5;
    font-size: 14px;
    height: 88px;
    max-height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    text-align: center
  }

  #fd .FdBottomMew {
    display: none;
  }


}

/* 桌面 */
@media (min-width: 1025px) {

  /* side */
  .side {
    position: fixed;
    width: 50px;
    height: 275px;
    right: 0;
    top: 250px;
    z-index: 100;
  }

  .side ul li {
    width: 50px;
    height: 50px;
    float: left;
    position: relative;
    margin-bottom: 2px;
  }

  .side ul li .sidebox a {
    color: #fff;
  }

  .side ul li .sidebox {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 5px 0 0 5px;
    top: 0;
    right: 0;
    transition: all 0.3s;
    background: #000022;
    color: #fff;
    font: 16px/50px "微软雅黑";
    overflow: hidden;
    padding-left: 10px;
  }

  .side ul li a:hover .sidebox {
    width: 175px;
    background: #3696d5;
  }

  .side ul li img {
    float: right;
  }

  .side2 {
    position: fixed;
    width: 40px;
    right: 10px;
    z-index: 100;
    top: 500px;
    height: 80px;
  }

  .side2 ul {
    position: relative;
  }

  .side2 ul li {
    width: 40px;
    height: 40px;
  }

  .side2 ul li a {
    display: block;
    background-color: #3696d5;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
  }

  .side2 ul li a img {
    vertical-align: middle;
    display: inline-block;
  }

  .side2 ul li a:hover {
    background-color: #000022;
  }


  .side2 ul li .weixin {
    position: absolute;
    right: 55px;
    bottom: -20px;
    display: block;
  }

  .side2 ul li .weixin div {
    background: url(./assets/skin/arr3.png) no-repeat;
    width: 5px;
    height: 17px;
    display: block;
    position: absolute;
    right: -10px;
    bottom: 50%;
    margin-top: 3px;
  }

  .side2 ul li .weixin img {
    width: 150px;

    display: block;
  }


  .logoPhoneMew {
    display: none;
  }

  .ulMenuDemo {
    display: none;
  }

  .headerBoxBox {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999;

  }

  .headerBox {
    height: 80px;
    color: #000;
    background-color: #fff;
    /* background: rgba(0, 0, 0, 0.7) !important; */
    display: flex;
    justify-content: between;
    align-items: center;
  }

  .headerBox .logoPhone {
    font-size: 18px;
    margin-left: 20%;
  }

  .headerBox .headerBoxPath {
    cursor: pointer;
    flex: 1;
    display: flex;

  }

  .headerBox .m-tigger {
    width: 20%;
  }

  .headerBox .headerBoxPath div {
    position: relative;
    margin-right: 2%;
  }

  .headerBox .active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: #B71F36;
    z-index: 1;

  }

  .headerBox div {
    line-height:5;

  }


  .headerBox .headerBoxPath div:last-child {
    margin: 0;
  }

  .headerBox .headerBoxPath div:last-child a {
    color: #000;
    font-size: 16px;
  }

  /* 桌面样式 */
  .el-menu--horizontal .el-menu-item {
    line-height: 80px !important;
    height: 80 !important;


  }

  .el-container.is-vertical {
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .el-header {
    background: rgba(0, 0, 0, 0.7) !important;
    height: 80px !important;
  }

  .el-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 1240px;
    margin: 0 auto;
    /* //background-color: #fff; */
  }

  .el-menu {
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: transparent !important;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    color: #fff !important;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 4px solid #fff !important;
  }

  .el-menu--horizontal>.el-menu-item {
    color: #fff !important;
  }

  .el-header .logo {
    width: 280px;
    display: flex;
    align-items: center;
    /* padding: 10px; */
  }

  .el-header .logoPhone {
    display: none;
  }

  .el-header .logo img {
    width: 58px;
    line-height: 56px;
    margin: 12px 0;
  }

  .el-main {
    padding: 0 !important;
  }

  .footer {
    width: 100%;
    height: 216px;
    overflow: hidden;
    background-color: #14679f;
  }

  .footer &-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .footer &-content .content-nav {
    display: flex;
    justify-content: space-around;
  }


  .footer &-content .content-nav li {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    align-items: flex-start;
  }

  .footer &-content .content-nav li p {
    font-size: 20px;
    color: #d4edff;
    padding: 10px 0;
  }

  .footer &-content .content-nav li span {
    color: #f7f7f7;
    font-weight: 300;
    padding: 5px 0;
  }

  .footer &-content img {
    width: 170px;
    height: 170px;
    padding: 10px;
  }

  .footer .copyright {
    height: 30px;
    background: #125688;
  }

  .footer span {
    color: #fff;
    line-height: 30px;
  }

  /* 底部 */
  #fd {
    background: #E5E5E5;
    color: #707070;
    font-size: 18px;
    padding-top: 66px;
  }

  #fd .wp {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
  }

  #fd .wp .fd-top {
    display: flex;
    flex: 1;
  }

  #fd .wp .fd-top dl {
    margin-right: 20%;
  }

  #fd .wp .fd-top dl:last-child {
    margin-right: 0;
  }

  a {
    font-size: 14px;
    text-decoration: none !important;
    color: #707070;
    outline: none;
  }

  ul,
  li {
    list-style-type: none;
  }

  #fd .wp .fd-top dt {
    font-size: 16px;
    color: #595757;
    margin-bottom: 28px;
    font-weight: 500;
  }

  #fd .wp .fd-top .ul-fd li,
  .ul-ff li {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  #fd .wp .fd-top .wo .ul-dd {
    display: flex;
    margin-right: 25px;
  }

  #fd .wp .fd-top .wo .ul-dd .ul-fd {
    margin-right: 25px;
  }

  #fd .fdCopy {
    margin-top: 10px;
    font-size: 14px
  }

  #fd .fdCopy .tel span {
    font-size: 16px
  }

  #fd .fdCopy .er {
    width: 140px;
    text-align: center;
    margin-top: 16px;
  }

  #fd .fdCopy .er div {
    margin-bottom: 6px;
    font-size: 13px;
  }

  #fd .FdBottom {
    background: #333;
    color: #E5E5E5;
    font-size: 16px;
    height: 88px;
    max-height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
  }

  #fd .FdBottomMew {
    display: none;
  }

}
</style>
